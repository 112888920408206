* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: url(background.svg) no-repeat center/cover;
}

.App {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background-color: #33333310;
}

.welcome-container {
  width: 100%;
  height: 80%;
  padding: 1rem;
  margin-top: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  border: 3px solid #f80;
  box-shadow: 0 0 4px 4px rgba(197, 197, 197, 0.7);
  background-color: rgba(0, 0, 0, 0.8);
}

.welcome-title {
  margin: 0.5rem;
  font-size: 3.5vh;
}
